// Define a scoring function to calculate the partial match score
interface ObjectDetails {
    [name: string]: any;
}

const calculatePartialMatchScore = (name: string, searchValue: string) => {
    const lowerCaseName = name.toLowerCase();
    const lowerCaseSearchValue = searchValue.toLowerCase();
    let score = 0;
    for (let i = 0; i < Math.min(lowerCaseName.length, lowerCaseSearchValue.length); i++) {
        if (lowerCaseName[i] === lowerCaseSearchValue[i]) {
            score++;
        } else {
            break;
        }
    }
    return score;
};

// Define a function to sort an array of objects by partial matches
export const sortByPartialMatch = <T extends ObjectDetails>(arrayOfObjects: readonly T[], searchValue: string, fieldName: string): T[] => {
    // Sort the array of objects based on the partial match scores
    return [...arrayOfObjects].sort((a, b) => {
        const scoreA = calculatePartialMatchScore(a[fieldName], searchValue);
        const scoreB = calculatePartialMatchScore(b[fieldName], searchValue);

        // If there is a complete match, prioritize it
        if (a[fieldName].toLowerCase() === searchValue.toLowerCase()) return -1;
        if (b[fieldName].toLowerCase() === searchValue.toLowerCase()) return 1;

        // Otherwise, sort by the partial match scores
        return scoreB - scoreA; // Sort in descending order of scores
    });
};
